import { set } from 'lodash'
import Question from './Question'
import useAct from '../hooks/useAct'

export default function ScriptEditor({
  campaignType = null,
  effortId = null,
  effortName = null,
  blastKind = null,
  enableOptOut = false,
  mergeTags = [] }) {
  const act = useAct()
  const questionIds = useSel(s => _.orderBy(_.values(s.questions.entities).filter(q => q.active), 'digitOrder').map(q => q.id))
  const adding = useSel(s => s.scriptBuilder.adding)

  return <div className='script-editor'>
    <div className='questions'>
      {questionIds.map(id =>
        <Question
          id={id}
          key={id}
          campaignType={campaignType}
          blastKind={blastKind}
          enableOptOut={enableOptOut}
          effortId={effortId}
          effortName={effortName}
          mergeTags={mergeTags} />)
      }
    </div>

    <a className='button secondary margin-top' onClick={() => act.scriptBuilder.setAdding(!adding)}>Add question/instruction</a>

    {adding &&
      <div className='popup popup-list narrow'>
        <div className='group'>
          <div className='filter' onClick={() => act.scriptBuilder.questions.add({ questionType: 'multiple_choice' })}>
            Multiple choice question
          </div>
          {campaignType !== 'sms_survey' &&
            <div className='filter' onClick={() => act.scriptBuilder.questions.add({ questionType: 'multiple_select' })}>
              Multiple select question
            </div>
          }
          {(questionIds.length > 0 || campaignType !== 'sms_survey') && <div className='filter' onClick={() => act.scriptBuilder.questions.add({ questionType: 'instruction' })}>
            Instruction
          </div>}
          <div className='filter' onClick={() => act.scriptBuilder.questions.add({ questionType: 'text' })}>
            Open question
          </div>
          {campaignType === 'phone' && <div className='filter' onClick={() => act.scriptBuilder.questions.add({ questionType: 'send_sms', settings: { textMessageBody: '' } })}>
            Send a text message
          </div>}
          {campaignType === 'phone' && <div className='filter' onClick={() => act.scriptBuilder.questions.add({ questionType: 'transfer', settings: { toPhoneNumber: '' } })}>
            Transfer the call
          </div>}
        </div>
      </div>
    }
  </div>
}
