import useOnScreen from 'Shared/hooks/useOnScreen'
import './EmailPreview.scoped.scss'
import MobileDesktopToggle from './MobileDesktopToggle/MobileDesktopToggle'
import { ordinal } from '../functions/util'

const formatDate = (date) => {
  const day = ordinal(date.getDate())
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()
  return `${day} ${month} ${year}`
}

const EmailPreview = ({
  html,
  subjectLine,
  internalWidth = 966,
  internalHeight = 768,
  showHeader = false,
  senderName = '',
  searchName = '',
  inline = false,
  maxHeight = false
}) => {
  const iframeRef = useRef(null)
  const [scale, setScale] = useState(0.1)
  const containerRef = useRef(null)
  const onScreen = useOnScreen(containerRef)
  const [display, setDisplay] = useState('desktop')
  const [contentHeight, setContentHeight] = useState(internalHeight)

  const setHeight = () => {
    setContentHeight(iframeRef.current?.contentWindow.document.body.scrollHeight)
  }

  const measure = () => {
    setScale(containerRef.current.parentNode.offsetWidth / internalWidth)

    setHeight()
    setTimeout(() => {
      // sometimes the iframe height isn't correct until a render loop
      setHeight()
    }, 250)
  }

  useEffect(() => {
    window.addEventListener("resize", measure)
    measure()

    return () => {
      window.removeEventListener("resize", measure)
    };
  }, [])

  useEffect(() => {
    measure()
  }, [onScreen])

  useEffect(() => {
    const doc = iframeRef.current?.contentWindow.document
    doc.open()
    doc.write(html)
    doc.close()
  }, [html])

  const widthScale = display === 'desktop' ? 1 : 0.5
  const iframeWidth = internalWidth * widthScale * scale
  const left = containerRef.current ? (containerRef.current.parentNode.offsetWidth - iframeWidth) / 2 : 0

  const iframeStyle = {
    width: inline ? '100%' : `${internalWidth * widthScale}px`,
    height: `${contentHeight}px`,
    flexShrink: 0,
    position: inline ? 'relative' : "absolute",
    left: left,
    transformOrigin: '0% 0%',
    transform: inline ? 'auto' : `scale(${scale})`
  }

  const containerStyle = {
    height: !inline ? '400px' : 'auto'
  }

  return (
    <div style={containerStyle} ref={containerRef}>
      {
        showHeader &&
        <div className="box email-preview-header">
          <div>
            <p>From: <strong>{senderName}</strong></p>
            <p>Recieved: <strong>{formatDate(new Date())}</strong></p>
          </div>
          <p>To: <strong>{searchName}</strong></p>
          <p>Subject: <strong>{subjectLine}</strong></p>
        </div>
      }
      <div style={{ position: 'relative' }}>
        {!inline && <MobileDesktopToggle value={display} onChange={setDisplay} />}
        <iframe ref={iframeRef} onLoad={measure} style={iframeStyle}></iframe>
      </div>
    </div>
  )
}

export default EmailPreview
