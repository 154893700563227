import CurrencyInput from 'react-currency-input-field'
import Button from 'Shared/components/Button'
import restClient from 'Shared/hooks/restClient'
import { CircularProgress } from '@material-ui/core'
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'

const currencySymbol = {
  GBP: '£',
  USD: '$',
}[window.ENV.CREDIT_CURRENCY]

function getAvailableCurrencies() {
  if (window.ENV.CREDIT_CURRENCY === 'GBP') {
    return ['GBP']
  } else if (window.ENV.CREDIT_CURRENCY === 'USD') {
    if (window.ENV.DISPLAY_CURRENCY === 'USD') {
      return ['USD']
    } else if (window.ENV.DISPLAY_CURRENCY === 'GBP') {
      return ['GBP', 'USD']
    }
  }
}

function validate(amount) {
  if (!amount) {
    return ''
  } else if (amount > 10000) {
    return `Maximum amount is ${currencySymbol}10,000`
  } else if (amount < 10) {
    return `Minimum amount is ${currencySymbol}10`
  }

  return null
}

export default function TopUpModal({ open = false, onCancel }) {
  const [amount, setAmount] = useState(50)
  const [remoteError, setRemoteError] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  // Debounce the error message appearing, but not the button disabling
  const [dError, dSetError] = useState(50)
  const ddSetError = useMemo(() => _.debounce(dSetError, 500))
  const error = validate(amount)
  ddSetError(error)

  function onSubmit(currency) {
    setSubmitting(true)
    restClient
      .post('/api/billing/stripe/session', { amount, currency })
      .then(({ data }) => {
        window.location.href = data.url
      })
      .catch(() => {
        setRemoteError('Something went wrong. Please try again.')
        setSubmitting(false)
      })
  }

  return (
    <BackdropModal title="Top up" onCancel={onCancel} open={open}>
      <div className="section">
        <div className="box form">
          <div className="field">
            <p>
              Enter the amount you want to top up. Once you pay, the credit will be
              available immediately.
            </p>
          </div>

          <div className="field save-changes margin-top">
            <label>Amount to top up (Max {currencySymbol}10,000)</label>
            <CurrencyInput
              name="amount_local_currency"
              placeholder="Please enter an amount"
              value={amount}
              decimalsLimit={2}
              onValueChange={(v) => setAmount(v)}
              prefix={currencySymbol}
              limit={10000}
            />
            <small>
              This is the amount of credit you will receive. Any applicable taxes, such as
              VAT, will be added at the checkout stage
            </small>
          </div>

          {error && dError && (
            <div>
              <p className="error">{error}</p>
            </div>
          )}

          {remoteError && (
            <div>
              <p className="error">{remoteError}</p>
            </div>
          )}

          {getAvailableCurrencies().map((currency) => (
            <div className="field save-changes margin-top" key={currency}>
              <Button
                key={currency}
                className="margin-left"
                secondary
                small
                disabled={error !== null || isSubmitting}
                onClick={() => onSubmit(currency)}
              >
                Checkout in {currency}
              </Button>
            </div>
          ))}

          {isSubmitting && <CircularProgress />}
        </div>
      </div>
    </BackdropModal>
  )
}
