import ScriptEditor from 'Shared/ScriptEditor/components/ScriptEditor'
import Step from './SurveyBuilderStep'
import detectCycle from 'CampaignBuilder/detectCycle'
import _ from 'lodash'
import Loading from 'Shared/components/Loading'
import validate from 'Shared/ScriptEditor/functions/validate'
import ShortDescriptor from 'Shared/ScriptEditor/components/ShortDescriptor'
import useAct from '../../hooks/useAct'
import useScriptActions from 'Shared/ScriptEditor/hooks/useAct'

export default function Script() {
  const act = useAct()
  const scriptActions = useScriptActions()
  const { id: surveyId } = useSel(s => s.survey)
  const { mergeTags } = useSel(s => s.builder)

  const questionCount = useSel(s => _.filter(_.values(s.questions.entities), o => _.includes(['multiple_choice', 'multiple_select', 'text', 'instruction', 'send_sms', 'transfer'], o.questionType)).length)
  const complete = questionCount > 0
  const [loading, setLoading] = useState(false)

  const [validationErrors, setValidationErrors] = useState([])
  const questions = useSel((s) => s.questions.entities)
  const answers = useSel((s) => s.answers.entities)

  useEffect(() => {
    _.defer(() => validate(Object.values(questions), Object.values(answers), setValidationErrors))
  }, [questions, answers])

  useEffect(() => {
    if(!surveyId) return
    setLoading(true)
    act.scriptBuilder.load(surveyId)
      .then(() => act.builder.loadMergeTags())
      .then(() => setLoading(false))
  }, [surveyId])

  function save() {
    if (validationErrors.length > 0) {
      return Promise.resolve(false)
    }

    if (detectCycle(
      Object.values(questions).filter(q => q.active),
      Object.values(answers).filter(a => a.active)
      )) {
      setValidationErrors(["Your script contains a loop which would mean someone could never finish"])
      return Promise.resolve(false)
    }

    return scriptActions.scriptBuilder.persist(surveyId).then(() => {
      act.builder.closeEditor()
    })
  }

  const handleDiscardChangesClick = () => {
    act.scriptBuilder.load(surveyId)
  }

  return <Step
    name='Script & Questions'
    editor='script'
    complete={complete}
    save={save}
    onDiscardChanges={handleDiscardChangesClick}
    shortDescriptor={ <ShortDescriptor questionCount={questionCount} /> }
    validationErrors={validationErrors}
    canClickFinish={validationErrors.length === 0}
  >
    { loading ?  <Loading /> : <ScriptEditor mergeTags={mergeTags} /> }
  </Step>
}
