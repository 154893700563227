import './MultipleSelect.scoped.scss'

export default function MultipleSelect({ answers, response, setResponse }) {
  const localResponse = Array.isArray(response) ? response : []

  // Enable Next button without picking any answers
  useEffect(() => setResponse(localResponse), [])

  const handleChange = (event) => {
    const value = +event.target.value
    const responseArray = [...localResponse]
    const index = responseArray.indexOf(value)
    if (event.target.checked && index === -1) {
      responseArray.push(value)
    } else {
      if (index > -1) {
        responseArray.splice(index, 1)
      }
    }
    setResponse(responseArray)
  }

  return (
    <div className="answers">
      {answers.map((answer) => (
        <label key={answer.digitOrder}>
          <input type="checkbox"
            checked={localResponse.includes(answer.id)}
            value={answer.id}
            onChange={handleChange}
          />
          {answer.answer}
        </label>
      ))}
    </div>
  )
}
