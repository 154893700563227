import * as React from 'react'

function SvgMore(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.665 15.833a1.667 1.667 0 10-3.333 0 1.667 1.667 0 003.333 0zM11.665 10a1.667 1.667 0 10-3.333 0 1.667 1.667 0 003.333 0zM11.665 4.167a1.667 1.667 0 10-3.333 0 1.667 1.667 0 003.333 0z"
        fill="#8792A2"
      />
    </svg>
  )
}

export default SvgMore
