import MoneyIcon from './MoneyIcon'
import Button from 'Shared/components/Button'
import Currency from 'Shared/components/Currency'
import './Overview.scoped.scss'
import PopoverLink from 'Shared/components/PopoverLink/PopoverLink'
import TopUpModal from './TopUpModal'

function DownloadInvoices() {
  return (
    <div className="box invoices">
      <h3>Download invoices</h3>
      <p className="light">Your June invoices will be available by the 10th of July</p>
      <form>
        <select>
          <option>May 2023</option>
        </select>
        <Button small dark primary>
          Download PDF
        </Button>
      </form>
    </div>
  )
}

export default function Overview() {
  const { remainingBudget, currentCredit, unsettledGbp, unsettledUsd } = useSel((s) => s.billingData)
  const [showTopUpModal, setShowTopUpModal] = useState(false)
  const enableTopUp = !!window.ENV.FEATURES['topup']
  const unsettled = () => {
    if (window.ENV.CREDIT_CURRENCY === 'GBP') {
      return unsettledGbp
    } else if (window.ENV.CREDIT_CURRENCY === 'USD'){
      return unsettledUsd
    }
  }

  return (
    <div className="overview">
      <div className="box">
        <div>
          <div className="balance">
            <MoneyIcon />
            <div>
              <h3>Current Balance</h3>
              <p className="money">
                <Currency
                  amount={remainingBudget}
                  displayCurrency={window.ENV.CREDIT_CURRENCY}
                />
              </p>
              {enableTopUp && (
                <Button medium primary dark onClick={() => setShowTopUpModal(true)}>
                  Top up
                </Button>
              )}
            </div>
          </div>
          <div>
            <div className="credit">
              <MoneyIcon size={48} />
              <div>
                <h3>Current credit</h3>
                <p className="money">
                  <Currency
                    amount={currentCredit}
                    displayCurrency={window.ENV.CREDIT_CURRENCY}
                  />
                </p>
              </div>
            </div>
            <div className="usage">
              <MoneyIcon size={48} />
              <div>
                <h3>Usage so far</h3>
                <p className="money">
                  <Currency
                    amount={unsettled()}
                    displayCurrency={window.ENV.CREDIT_CURRENCY}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className="light no-margin-bottom">
          Some of these figures are approximate.&nbsp;
          <PopoverLink
            href="#"
            content={() =>
              'Finalised expenditure figures are available at the end of each calendar month, once the exchange rate from $USD to your currency is known.'
            }
          >
            Why is this?
          </PopoverLink>
        </p>
      </div>

      {/* <DownloadInvoices /> */}

      <TopUpModal open={showTopUpModal} onCancel={() => setShowTopUpModal(false)} />
    </div>
  )
}
