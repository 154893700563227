import Table from "Shared/components/DataTable/Table"
import restClient from "Shared/hooks/restClient"
import BookmarkedCampaigns from "./BookmarkedCampaigns"

const CampaignsDatatable = () => {
  const handleTableClick = (e) => {
    const button = e.target.closest('button')
    if (button && button.classList.contains('toggle-bookmark')) {
      restClient
        .put('/api/me/toggle_campaign_bookmark', { campaign_id: button.dataset.id })
        .then(reloadTable)
    }
  }

  const tableProps = {
    table: 'campaigns',
    scope: {},
    language: { emptyTable: 'There are no matching campaigns', searchPlaceholder: 'Search by name' },
    onClick: handleTableClick,
  }

  const reloadTable = () => {
    window.$('table').DataTable().ajax.reload()
  }

  return (
    <>
      <BookmarkedCampaigns />

      <div className="box">
        <Table {...tableProps} />
      </div>
    </>
  )
}

export default CampaignsDatatable
