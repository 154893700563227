const columns = [
  {
    data: 'name',
    className: 'bold',
    render: (data, type, row, meta) => {
      return row.status === 'live' ?
        `<a href="/admin/surveys/${row.DT_RowId}/overview">${data}</a>` :
        `<a href="/admin/surveys/${row.DT_RowId}/build">${data}</a>`
    },
  },
  {
    data: 'kind',
  }
]

const headers = [
  'Name',
  'Kind',
]

const filters = []

const sorters = []

const order = [[0, "asc"]]

export { columns, headers, filters, sorters, order }
