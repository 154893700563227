import { Container } from '@material-ui/core'

export const Root = styled(Container)`
  background: #F9F9FC;
  border-radius: 12px;
  padding: 25px 35px!important;
  max-height: 80vh;
  overflow-y: auto;
  display: flex!important;
  flex-direction: column;
`
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  > h1 {
    font-size: 32px;
    margin: 0;
  }
`
export const Content = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 24px 24px 24px 24px;
`
