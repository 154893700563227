import Question from 'DesktopCaller/components/Script/Question'
import useData from 'DesktopCaller/useData'

export default function Questions() {
  const currentQuestionId = useData(rs => rs.call.currentQuestionId)
  const script = useSel(s => s.local.currentScript, _.isEqual)

  const answeredQuestionIds = useData(rs => _.map(_.keys(rs.callSession.questionAnswers || {}), id => parseInt(id)), _.isEqual)

  const visibleQuestionIds = useSel(s => {
    const currentDigitOrder = s.questions.entities[currentQuestionId]?.digitOrder || 99999

    const visibleQuestionIds = _.compact(
      _.uniq([...answeredQuestionIds, currentQuestionId])
    )

    const showQuestions = _.values(s.questions.entities).filter(q =>
      q.questionType !== 'intro' && _.includes(visibleQuestionIds, Number(q.id))
    )

    // Order + return
    let index = 0
    return _.orderBy(showQuestions, 'digitOrder').map(q => {
      if (_.includes(['text', 'multiple_choice', 'multiple_select', 'send_sms', 'transfer'], q.questionType)) index++
      return { id: Number(q.id), index: index }
    })
  })

  if (!script) {
    return null
  }

  let i = 0

  return (
    <>
      {visibleQuestionIds.map(q => {
        return <Question key={q.id} id={q.id} index={q.index} />
      })}
    </>
  )
}
