import createTwilioManager from './twilioManager'
import createPusherManager from 'Shared/pusherManager'
import pluralize from 'pluralize'
import { appsignal } from 'lib/appsignal'
import { newrelic } from 'lib/newrelic'


export default function actions(dis, store, restClient) {
  let errorCount = 0;

  const twilioErrorPayload = { connected: false, deviceActive: false, callingMode: null, showInputSetup: true }

  const twilioManager = createTwilioManager({
    onError: error => {
      console.error(error)
      dis({ type: 'local/upsert', payload: { ...twilioErrorPayload, twilioInitializeError: true } })
      appsignal?.sendErrorCustom(error)
      newrelic?.noticeError(error)
    },
    onDeviceError: error => {
      console.error(error)
      dis({ type: 'local/upsert', payload: { ...twilioErrorPayload, twilioDeviceError: true } })
      appsignal?.sendErrorCustom(error)
      newrelic?.noticeError(error)
    },
    onConnected: () => {
      dis({ type: 'local/upsert', payload: { connected: true, deviceActive: true } })
    }
  })

  const pusherManager = createPusherManager(handlePusherPayload, handleLatePayload, handlePusherConnected)

  function handlePusherConnected() {
    dis({ type: 'local/upsert', payload: { pusherConnected: true } })
  }

  function handlePusherPayload(payload) {
    _.each(payload, (entities, name) => {
      if (name === 'sentAt') return
      const model = pluralize(name)
      dis({ type: `${model}/setAll`, payload: entities })
    })
  }

  restClient.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      errorCount++

      if (errorCount == 5) {
        return window.location = window.ENV.ERROR_URL
      }

      const config = error?.config || {}
      const response = error?.response || {}

      error.name = `${(config.method || '').toUpperCase()} ${config.url} ${response.status}`
        .replace(/\/\d+/g, "/[id]")
        .replace(/[a-f0-9]{32}/g, "[hash]")

      appsignal?.sendErrorCustom(error)
      return Promise.reject(error)
    }
  )

  function startPresence() {
    function postPresence() {
      restClient.post(`/presence`)
    }
    postPresence()
    const interval = setInterval(postPresence, 60000)
    return () => clearInterval(interval)
  }

  function getUser() {
    return restClient.get(`/api/me`)
      .then(({ data }) => {
        dis({ type: 'users/setAll', payload: [data] })
      })
  }

  function getCampaign(campaignId) {
    return restClient.get(`/api/campaigns/${campaignId}/show_volunteer`)
      .then(({ data }) => {
        dis({ type: 'campaigns/setAll', payload: [data] })
      })
  }

  function loadPhoneNumbers(campaignId) {
    return restClient.get(`/api/campaigns/${campaignId}/phone_numbers`)
      .then(({ data }) => {
        dis({ type: 'campaign/upsert', payload: { phoneNumbers: data } })
      })
  }

  function confirmAudio() {
    return restClient.post(`/api/call_sessions/confirm_audio`)
  }

  function answerOutcome(outcome) {
    return restClient.post(`/api/call_sessions/answer_outcome`, { outcome })
      .then(() => {
        dis({ type: 'desktopCaller/upsert', payload: { answeredQuestionIds: [] } })
        return Promise.resolve()
      })
  }

  function undoLast() {
    return restClient.post(`/api/call_sessions/undo_last`)
  }

  function answerQuestion(id, answerId) {
    return restClient.post(`/api/call_sessions/answer_question`, { questionId: id, answerId })
      .then(() => {
        dis({ type: 'desktopCaller/addQuestionAnswer', payload: { [id]: answerId } })
      })
  }

  function answerMultipleSelectQuestion(id, answerIds) {
    return restClient.post(`/api/call_sessions/answer_question`, { questionId: id, answerIds: Array.from(answerIds) })
      .then(() => {
        dis({ type: 'desktopCaller/addQuestionAnswer', payload: { [id]: Array.from(answerIds) } })
      })
  }

  function answerQuestionWithText(id, answerText) {
    return restClient.post(`/api/call_sessions/answer_question_with_text`, { questionId: id, answerText: answerText })
      .then(() => {
        dis({ type: 'desktopCaller/addQuestionAnswerText', payload: { [id]: answerText } })
      })
  }

  function answerSendSmsQuestion(id, decision) {
    return restClient.post(`/api/call_sessions/answer_send_sms_question`, { questionId: id, decision })
      .then(() => {
        dis({ type: 'desktopCaller/addQuestionAnswerText', payload: { [id]: decision } })
      })
  }

  function answerTransferQuestion(id, decision) {
    return restClient.post(`/api/call_sessions/answer_transfer_question`, { questionId: id, decision })
      .then(() => {
        dis({ type: 'desktopCaller/addQuestionAnswerText', payload: { [id]: decision } })
      })
  }

  function answerInstructionQuestion(id) {
    return restClient.post(`/api/call_sessions/answer_instruction_question`, { questionId: id })
  }

  function connectSupervisor() {
    return restClient.post(`/api/call_sessions/connect_supervisor`)
  }

  function resetQuestions() {
    dis({
      type: 'desktopCaller/upsert', payload: {
        answeredQuestionIds: [],
        questionAnswers: {},
        answerTexts: {}
      }
    })
  }

  function rateCall(rating, ratingReason, notes) {
    restClient.post(`/api/call_sessions/rate_call`, { rating, ratingReason, notes })
  }

  function createCallSession(callingMode) {
    const demo = store.getState().local.demo
    const deviceInfo = store.getState().local.deviceInfo || {}

    restClient.post(`/api/call_sessions`, { callingMode, demo: !!demo, deviceInfo })
      .then(({ data }) => {
        const callSessionId = data.callSessions[0].id
        pusherManager.setChannelName(`call-session-${callSessionId}`)
        handlePusherPayload(data)

        dis({ type: 'local/upsert', payload: { callingMode, callSessionId } })
        setTimeout(() => dis({ type: 'local/upsert', payload: { pusherSubscribed: true } }), 1000)
      })
  }

  function handleLatePayload(channelName, reason) {
    const callSessionId = parseInt(channelName.replace('call-session-', ''))
    getCallSession(callSessionId, reason)
  }

  function getCallSession(callSessionId, reason = '') {
    restClient.get(`/api/call_sessions/${callSessionId}${reason.length > 0 ? `?reason=${reason}` : ''}`)
      .then(({ data }) => {
        handlePusherPayload(data)
      })
  }

  let lastRequestedTargetId = null
  function fetchScript(campaignId, targetId) {
    // This is a hack around the fact that we can't seem to stop the repeated requests. Will be resolved once Jason is gone
    if (lastRequestedTargetId === targetId) return Promise.resolve()
    lastRequestedTargetId = targetId

    restClient
      .get(`/api/render/script_for_target`, { params: { campaign_id: campaignId, target_id: targetId } })
      .then(({ data: script }) => {
        dis({ type: 'local/upsert', payload: { currentScript: script } })
      })
  }

  function setDevices(devices) {
    dis({
      type: 'local/upsert', payload: {
        avDevices: devices,
        inputsConfigured: true
      }
    })

    twilioManager.setDevices(devices)
  }

  function setupTwilio() {
    return twilioManager.connect()
  }

  function scheduleCallback(at) {
    restClient.post(`/api/call_sessions/schedule_callback`, { at })
  }

  function redial() {
    return restClient.post(`/api/call_sessions/redial`).then(() => {
      resetQuestions()
      return Promise.resolve()
    })
  }

  function hangUp() {
    const callingMode = store.getState().local.callingMode

    if (callingMode === 'desktop_web') {
      twilioManager.hangUp()
      restClient.post(`/api/call_sessions/hang_up`)
      return Promise.resolve('Success')
    } else {
      return restClient.post(`/api/call_sessions/hang_up`)
    }
  }

  function disconnect() {
    const callingMode = store.getState().local.callingMode

    const { slug } = Object.values(store.getState().campaigns.entities)[0]

    if (callingMode === 'desktop_web') {
      twilioManager.disconnect()
    } else {
      restClient.post(`/api/call_sessions/disconnect`)
    }

    setTimeout(
      () => (window.location = `/campaigns/${slug}`),
      500
    )

    // always resolve so we exit the flow
    return Promise.resolve('Success')
  }

  return {
    local: {
      upsert(payload) {
        dis({ type: 'local/upsert', payload })
      },
    },
    desktopCaller: {
      getCampaign,
      loadPhoneNumbers,
      confirmAudio,
      answerOutcome,
      undoLast,
      answerQuestion,
      answerMultipleSelectQuestion,
      answerInstructionQuestion,
      answerQuestionWithText,
      answerSendSmsQuestion,
      answerTransferQuestion,
      connectSupervisor,
      rateCall,
      scheduleCallback,
      setupTwilio,
      setDevices,
      hangUp,
      sendDigit: twilioManager.sendDigit,
      disconnect,
      redial,
      createCallSession,
      getCallSession,
      fetchScript,
      startPresence
    }
  }
}
