import Select from 'react-select'

export default function SubscriptionTopicsSelect({ onChange, value }) {
  const subscriptions = window.ENV.AVAILABLE_SUBSCRIPTIONS

  const buildOptions = (key) =>
    (subscriptions[key] || []).map(({ topic, label }) => ({ label, value: `${key}:${topic}` }))

  const options = [
    {
      label: "Email",
      options: buildOptions('email')
    },
    {
      label: "Phone",
      options: buildOptions('phone')
    },
    {
      label: "SMS",
      options: buildOptions('sms')
    },
    {
      label: "WhatsApp",
      options: buildOptions('whatsapp')
    },
  ]
  const flatOptions = options.map((option) => option.options).flat()
  const selectedOption = flatOptions.find((option) => {
    if (!(value || '').includes(':')) {
      value = `${value}:default`
    }
    return option.value === value
  })

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '200px',
    }),
  }

  return (
    <Select options={options} onChange={onChange} value={selectedOption} styles={customStyles} menuPosition="fixed" classNamePrefix="subscription-topics-select" />
  )
}

