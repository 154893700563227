import './Score.scoped.scss'

export default function Score({ answers, response, setResponse, name }) {
  const handleChange = (event) => {
    if(event.target.checked){
      setResponse(+event.target.value);
    }
  }

  return (
    <div className="answers">
    {answers.map((answer) => (
      <label key={answer.digitOrder}>
        <input type="radio" 
          name={name}
          value={answer.id} 
          checked={answer.id === response}
          onChange={handleChange} />
        {answer.answer}
      </label>
    ))}
  </div>
  )
}
