import useSteps from 'CampaignBuilder/useSteps'
import isEditing from 'CampaignBuilder/isEditing'
import Effort from './Steps/Effort'
import Audience from './Steps/Audience'
import From from './Steps/From'
import PhoneFrom from './Steps/PhoneFrom'
import Instructions from './Steps/Instructions'
import TextMessageContent from './Steps/TextMessageContent'
import WhatsAppContent from './Steps/WhatsAppContent'
import Script from './Steps/Script'
import Users from './Steps/Users'
import PreviewScript from './Steps/PreviewScript'
import PreviewSms from './Steps/PreviewSms'
import PreviewSmsSurvey from './Steps/PreviewSmsSurvey'
import EmailFrom from './Steps/EmailFrom'
import EmailSubject from './Steps/EmailSubject'
import EmailContent from './Steps/EmailContent'
import PreviewEmail from './Steps/PreviewEmail'
import Settings from './Steps/Settings'
import SendBlast from 'SendBlast/components/SendBlast'
import Snackbar from 'Shared/components/Snackbar'
import Modal from 'Shared/components/Modal'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import EditableName from 'Shared/components/EditableName/EditableName'
import CannedResponses from './Steps/CannedResponses'
import { ManageMenuItem } from 'Shared/components/ManageMenu'
import ManageMenuComponent from 'Shared/components/ManageMenu/ManageMenuComponent'
import userCan from 'Shared/functions/userCan'
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import CampaignName from 'Shared/components/CampaignName'
import App from 'DesktopCaller/av-devices-setup/AVDevicesSetup'

const Builder = ({
  campaignsIndexUrl,
  campaignId,
  searchesNewUrl,
  userPhoneNumber,
  userEmail,
}) => {
  const act = useAct()
  const dis = useDis()
  const currentUser = useSel((s) => s.currentUser)
  const { redirectUrl } = useSel((s) => s.campaign)
  const { id, status, name, campaignType, textMessageBlast, emailBlast, lastSavedAt, approvalStatus, approvalFeedback, isWorkflowControlled } =
    useSel((s) => s.campaign)
  const steps = campaignType ? useSteps(campaignType, status, textMessageBlast?.kind, isWorkflowControlled) : []

  useEffect(() => {
    act.local.upsert({ userPhoneNumber, userEmail })
    act.campaign.load(campaignId)
    act.campaignBuilder.loadMergeTags()
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      act.campaign.upsert({ lastSavedAt: null })
    }, 2000)
    return () => clearTimeout(timeout)
  }, [lastSavedAt])

  function save() {
    return act.campaign.persist().then(() => {
      history.back()
    })
  }

  function exit() {
    window.location = isWorkflowControlled ? redirectUrl : campaignsIndexUrl
  }

  function archive() {
    act.campaign.archive()
  }

  const [duplicateCampaignModalVisible, setDuplicateCampaignModalVisible] =
    useState(false)

  const items: ManageMenuItem[] = useMemo(() => {
    const items = []
    if (userCan('create_campaigns')) {
      items.push({
        title: 'Clone campaign',
        onClick: () => {
          dis({
            type: 'createCampaign/upsert',
            payload: { type: campaignType, cloneFromId: id },
          })
          setDuplicateCampaignModalVisible(true)
        },
      })
    }

    const act = status === 'live' ? 'Archive' : 'Delete'
    items.push({
      title: `${act} campaign`,
      confirmValue: status === 'live' ? 'archive' : 'delete',
      className: 'danger',
      onClick: () => {
        archive()
      },
    })

    return items
  }, [status, userCan('create_campaigns')])
  const components = useMemo(() => {
    return [
      <BackdropModal
        title="Clone Campaign"
        open={duplicateCampaignModalVisible}
        onCancel={() => setDuplicateCampaignModalVisible(false)}
        key="duplicate_campaign_backdrop"
      >
        <div className="wizard">
          <div className="step">
            <CampaignName
              type={campaignType}
              askAttachSearch
              onCancel={() => setDuplicateCampaignModalVisible(false)}
            />
          </div>
        </div>
      </BackdropModal>,
    ]
  }, [duplicateCampaignModalVisible])

  function ApprovalBanner() {
    if (approvalStatus === 'awaiting_approval') {
      if (currentUser.allPermissions?.includes('manage_campaigns')) {
        return <div className="banner warning inline margin-top margin-bottom">
          This campaign is currently in the approval process. You can make any edits you need before approving. Once ready you can approve or reject from the campaigns menu.
        </div>
      } else {
        return <div className="banner warning inline margin-top margin-bottom">
          This campaign is currently in the approval process. You will be notified once it is approved. If you make any edits this will remove it from the approval process and you will need to resubmit it.
        </div>
      }
    } else if (approvalStatus === 'rejected') {
      return <>
        <div className="box margin-bottom">
          <div className="banner error inline margin-bottom">
            This campaign has been rejected. You can make any edits you need before resubmitting it for approval.
          </div>
          <strong>Reason for rejection</strong>: { (approvalFeedback || '').split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      </>
    } else {
      return null
    }
  }

  const loaded = id && currentUser

  return (
    <div className="builder">
      {!loaded && <p>Loading</p>}
      {id && (
        <>
          <div className="header editable-name-container margin-bottom double">
            <div className="align-center">
              <EditableName
                modelName="campaign"
                name={name}
                endpoint={`/api/campaigns/${campaignId}`}
              />
              {lastSavedAt && <span className="badge red margin-left">Saved</span>}
            </div>
            <div className="actions">
              <ManageMenuComponent items={items} components={components} title="Manage" />
              {status !== 'live' && !isEditing(campaignType, status) && (
                <button
                  onClick={() => exit()}
                  className={`button ${
                    status === 'live' ? 'naked' : 'primary'
                  } margin-right`}
                >
                  Finish later
                </button>
              )}
              {(status === 'live' || isEditing(campaignType, status)) && (
                <ServerWaitButton
                  onClick={() => save()}
                  className="button primary margin-right"
                >
                  Save
                </ServerWaitButton>
              )}
            </div>
          </div>

          <ApprovalBanner />

          <div className="steps">
            {steps.map((stepName) => {
              return (
                <div key={stepName}>
                  {stepName === 'effort' && <Effort />}
                  {stepName === 'audience' && (
                    <Audience searchesNewUrl={searchesNewUrl} />
                  )}
                  {stepName === 'from' && <From />}
                  {stepName === 'phoneFrom' && <PhoneFrom />}
                  {stepName === 'instructions' && <Instructions />}
                  {stepName === 'content' && <TextMessageContent />}
                  {stepName === 'whatsAppContent' && <WhatsAppContent />}
                  {stepName === 'script' && <Script />}
                  {stepName === 'users' && <Users />}
                  {stepName === 'emailFrom' && <EmailFrom />}
                  {stepName === 'emailSubject' && <EmailSubject />}
                  {stepName === 'emailContent' && <EmailContent />}
                  {stepName === 'settings' && <Settings />}
                  {stepName === 'previewEmail' && <PreviewEmail />}
                  {stepName === 'previewScript' && (
                    <PreviewScript campaignsIndexUrl={campaignsIndexUrl} />
                  )}
                  {stepName === 'previewSms' && <PreviewSms />}
                  {stepName === 'previewSmsSurvey' && <PreviewSmsSurvey />}
                  {stepName === 'cannedResponses' && <CannedResponses />}
                </div>
              )
            })}
          </div>
          {(textMessageBlast || emailBlast) && (
            <SendBlast
              campaignId={id}
              textMessageBlastId={textMessageBlast?.id}
              emailBlastId={emailBlast?.id}
              redirectUrl={campaignsIndexUrl}
            />
          )}
        </>
      )}
      <Snackbar />
      <Modal />
    </div>
  )
}

export default Builder
