import './MultipleChoice.scoped.scss'

export default function MultipleChoice({ answers, response, setResponse, name }) {
  const handleChange = (event) => {
    if(event.target.checked){
      setResponse(+event.target.value);
    }
  }

  return (
    <div className="answers">
      {answers.map((answer) => (
        <label key={answer.digitOrder}>
          <input type="radio" 
            name={name}
            value={answer.id} 
            checked={response === answer.id}
            onChange={handleChange} />
          {answer.answer}
        </label>
      ))}
    </div>
  )
}
