import { Provider } from 'react-redux'
import useAct from '../useAct'
import QuestionStats from 'Shared/Surveys/components/QuestionStats'
import StatList from 'Shared/components/StatList'
import Loading from 'Shared/components/Loading'
import EditableName from 'Shared/components/EditableName/EditableName'
import ExportConfirmationModal from 'Shared/components/ExportConfirmationModal'

import useScriptActions from 'Shared/ScriptEditor/hooks/useAct'
import store from '../store'
import SurveyActions from './Actions'

export default function SurveyOverview({ surveyId }) {
  return (
    <Provider store={store}>
      <OverviewContent surveyId={surveyId} />
    </Provider>
  )
}


function OverviewContent({ surveyId }) {
  const act = useAct()
  const scriptActions = useScriptActions()
  const { name, stats } = useSel((s) => s.survey)
  const [loadingQuestions, setLoadingQuestions] = useState(true)
  const [exportPassword, setExportPassword] = useState(null)


  const displayStats = stats ? [
    { label: 'Surveys started', value: stats.surveysStarted },
    { label: 'Surveys completed', value: stats.surveysCompleted },
  ] : []

  useEffect(() => {
    if (surveyId) {
      setLoadingQuestions(true)
      act.survey.load(surveyId)
      act.survey.loadStats(surveyId)
      scriptActions.scriptBuilder.load(surveyId).then(() => setLoadingQuestions(false))
      const questionInterval = setInterval(() => {
        scriptActions.scriptBuilder.load(surveyId)
        act.survey.loadStats(surveyId)
      }, 5000)
      return () => {
        clearInterval(questionInterval)
      }
    }
  }, [surveyId])

  return (
    <div>
      <ExportConfirmationModal
        exportPassword={exportPassword}
        setExportPassword={setExportPassword}
        entityName="survey" />

      <div className="header editable-name-container margin-bottom double">
        <EditableName
          modelName="survey"
          name={name}
          endpoint={`/api/surveys/${surveyId}`}
        />
        <SurveyActions id={surveyId} />
      </div>

      <div className="box">
        {stats ? <StatList stats={displayStats} /> : <Loading height="100px" />}
      </div>
      <div className="margin-top">
        {loadingQuestions ? <Loading /> : <QuestionStats />}
      </div>
    </div>
  )
}
