import TextOverview from 'CampaignOverview/components/TextOverview'
import EmailOverview from 'CampaignOverview/components/EmailOverview'

export default function PageContainer({ id, textMessageBlastId, emailBlastId }) {
  const act = useAct()
  useEffect(() => {
    act.local.upsert({ campaignId: id, textMessageBlastId, emailBlastId })
    const poll = () => {
      act.campaign.load(id)
    }
    const interval = setInterval(poll, 5000)
    poll()

    return () => clearInterval(interval)
  }, [])

  const campaignType = useSel((s) => s.campaign.campaignType)

  if (_.includes(['sms_survey', 'sms_blast', 'sms_conversations'], campaignType)) return <TextOverview />
  if (campaignType === 'email') return <EmailOverview />
  return null
}
