import restClient from 'Shared/hooks/restClient'
import { useMemo } from 'react'
import { ManageMenuPropsHookResult } from '../hooks'

export function useTargetActions(target) {
  return [
    {
      type: 'menu',
      props: {
        type: 'target',
        target,
      },
    },
  ]
}

export function useTargetMenuProps(target): ManageMenuPropsHookResult {
  const items = useMemo(() => {
    if (!target) {
      // Handle null or undefined target here if necessary
      return [];
    }

    return [
      {
        title: 'Permanently Delete',
        confirmValue: 'delete',
        className: 'danger',
        onClick: () => {
          restClient.delete(`/api/targets/${target.id}`).then(() => {
            window.location.href = '/admin/audience';
          });
        },
      },
    ];
  }, [target?.id])

  const childrenComponents = useMemo(() => {
    return []
  }, [])
  return [items, childrenComponents]
}
