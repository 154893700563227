import './Answer.scoped.scss'

import { useDrag } from 'react-dnd'
import { useDrop } from 'react-dnd'
import PillEditor from 'Shared/components/PillEditor/PillEditor'
import NextAction from './NextAction'
import { useCallback } from 'react'
import useAct from '../hooks/useAct'

export default function Answer ({ id, showAdvanced, readOnly, effortId, effortName, mergeTags }) {
  const act = useAct()
  const answers = useSel(s => _.values(s.answers.entities).filter(a => a.active))
  const { answer, questionId, digitOrder, action, nextQuestionId, setsGoal } = useSel(s => s.answers.entities[id])

  const [, drag, preview] = useDrag({
    item: { type: `${questionId}-answer`, id }
  });

  const [, drop] = useDrop({
    accept: `${questionId}-answer`,
    drop(item, monitor) {
      act.scriptBuilder.answers.moveDigitOrder(item.id, digitOrder, { questionId })
    }
  });

  function toggleGoal() {
    act.scriptBuilder.answers.upsert(id, { setsGoal: !setsGoal })
  }

  const ref = el => drag(drop(el))

  return <div className='answer' ref={ref}>
    <div className='dragger'><div className='handle'></div></div>
    <div className="content-editor condensed">
      { readOnly ? <input type="text" value={answer} disabled className='full-width' /> : <PillEditor 
      condensed={true} 
      initialValue={answer} 
      onChange={useCallback((body) => act.scriptBuilder.answers.upsert(id, { answer: body }), [id])} 
      availableTags={mergeTags} /> }
    </div>
    { showAdvanced && <div className='advanced-settings'>
      <NextAction
        answerId={id}
        questionId={questionId}
        action={action}
        answers={answers}
        nextQuestionId={nextQuestionId}
        onChange={(option) => act.scriptBuilder.answers.upsert(id, option.value)}
      />
      { effortId && <label className='margin-top half align-center complete-goal'>
        <input type='checkbox' className='margin-right half' checked={setsGoal} onClick={(e) => toggleGoal()} />
        Complete goal for { effortName }
      </label> }
    </div> }
    { !readOnly && <button className='delete' onClick={() => act.scriptBuilder.answers.markInactive(id)}><SvgIconsClose /></button> }
  </div>
}
