import Appsignal from '@appsignal/javascript'
import * as AppsignalWindowEvents from '@appsignal/plugin-window-events'

if (window.ENV?.APPSIGNAL_FRONTEND_API_KEY) {
  var _appsignal = new Appsignal({
    key: window.ENV.APPSIGNAL_FRONTEND_API_KEY || 'none',
    revision: window.ENV?.RELEASE || 'none',
    ignoreErrors: [
      // Global errors to ignore
      /UnhandledPromiseRejectionError/,
      /Request aborted/,

      // Combine URL-specific errors with URL patterns
      /Network Error.*\/api\/notifications/,
      /Network Error.*\/global_admin\//,

      // Additional patterns to cover URL-specific cases
      /Error.*\/api\/notifications/,
      /Error.*\/global_admin\//
    ]
  })

  const defaultTags = {
    clientId: window.ENV.CLIENT_ID,
    clientName: window.ENV.CLIENT_NAME,
    userId: window.ENV.USER_ID,
    userName: window.ENV.USER_NAME,
    hotjarSessionId: window.ENV.REQUEST_ID || null
  }

  // Custom sendError function to add tags
  _appsignal.sendErrorCustom = (error, spanFn = () => { }) => {
    if (window.hj) { // hotjar
      window.hj('event', `error_occurred`)
    }

    _appsignal.sendError(error, (span) => {
      span.setTags(defaultTags)
      spanFn(span)
    })
  };
} else {
  var _appsignal = null
}

export const appsignal = _appsignal;
export const initAppSignal = () => {
  _appsignal.use(AppsignalWindowEvents.plugin())

  return _appsignal
}
