import './Text.scoped.scss'

export default function Text({response, setResponse}) {
  const handleChange = (event) => {
    setResponse(event.target.value);
  }

  return (
    <div className="answers">
      <textarea placeholder="Type your answer..." onChange={handleChange} value={response || ''}></textarea>
    </div>
  )
}
