import Tabs from "Shared/components/Tabs/DynamicTabs"
import Tab from "Shared/components/Tabs/Tab"
import TabPanel from "Shared/components/Tabs/TabPanel"
import AsyncSelect from 'Shared/components/AsyncSelect'
import './FilterList.scoped.scss'
import restClient from 'Shared/hooks/restClient'
import Select from 'react-select'
import SubscriptionTopicsSelect from 'Shared/components/SubscriptionTopicsSelect'

function RadioFilter({ onChange, filter, value }) {
  const { name, options } = filter
  return (
    <div className="radio-filter">
      {options.map((option, index) => (
        <div key={index} className="radio-option">
          <label>
            <input type="radio" name={name} checked={value === option.value} value={option.value} onClick={() => onChange(name, option.value, option.label)} />
            {option.label}
          </label>
        </div>
      ))}
    </div>
  )
}

function AsyncFilter({ filter, value, onChange, removeFilter }) {
  const { name, entity } = filter
  return (
    <div className="field">
      <div style={{ display: 'inline-block', marginLeft: '5px' }}>
        <AsyncSelect
          entity={entity}
          value={value}
          onChange={(v, objs) => {
            if (objs) {
              onChange(name, v, objs.label)
            } else {
              removeFilter(name);
            }
          }
          }
          isClearable
        />
      </div>
    </div>
  )
}

function SubscriptionTopicFilter({ filter, value, onChange }) {
  const { name } = filter
  const handleChange = (option) => onChange(name, option.value, option.label)

  return (
    <div class="field">
      <SubscriptionTopicsSelect onChange={handleChange} value={value} />
    </div>
  )
}


export default function FilterList({ filters, filterState, close, setFilter, removeFilter }) {
  const [tabIndex, setTabIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const subscriptions = window.ENV.AVAILABLE_SUBSCRIPTIONS

  const handleTabChange = (index) => {
    setTabIndex(index)
  }

  const onChange = (name, value, label) => {
    close()
    setFilter(name, value, label)
  }

  const leftOffset = -90
  const popoverStyle = {
    position: 'absolute',
    left: `${leftOffset}px`
  }

  const onlyDefaultTopics = Object.values(subscriptions).flat().every((sub) => sub.topic === 'default')

  // Hide the subscription topic filter if all topics are default
  const filteredFilters = onlyDefaultTopics ? filters.filter((filter) => filter.kind !== 'subscription-topic') : filters

  return (
    <div className="popup-list popup" style={popoverStyle}>
      <Tabs value={tabIndex} onChange={setTabIndex} dropdown={true}>
        {filters.map((filter, index) => {
          const value = filterState.find(f => f.name === filter.name)?.value;
          return (
            <React.Fragment key={index}>
              <Tab
                label={filter.name}
                value={index}
                onClick={() => handleTabChange(index)}
                selected={tabIndex === index}
                dropdown={true}
              />
              <TabPanel value={tabIndex} index={index}>
                {filter.kind === 'radio' && <RadioFilter filter={filter} value={value} onChange={onChange} />}
                {filter.kind === 'radio-async' && <RadioFilter filter={filter} value={value} onChange={onChange} />}
                {filter.kind === 'async' && <AsyncFilter filter={filter} value={value} onChange={onChange} removeFilter={removeFilter} />}
                {filter.kind === 'subscription-topic' && <SubscriptionTopicFilter filter={filter} value={value} onChange={onChange} />}
              </TabPanel>
            </React.Fragment>
          )
        })}
      </Tabs>
    </div>
  )
}

