import useAct from 'DesktopCaller/useAct'
import SmsQuestion from 'DesktopCaller/components/Script/SmsQuestion'
import TransferQuestion from 'DesktopCaller/components/Script/TransferQuestion'
import useData from 'DesktopCaller/useData'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import RenderBreaks from 'Shared/components/RenderBreaks'
import Radio from 'Shared/components/Radio'
import Checkbox from 'Shared/components/Checkbox'
import LabeledCard from 'Shared/components/LabeledCard/LabeledCard'
import './Question.scoped.scss'


export default function Question({ index, id }) {
  const script = useSel((s) => s.local.currentScript)
  const inputStage = useData((rs) => rs.callSession.inputStage)
  const previousQuestionId = useData((rs) => rs.callSession.previousQuestionId)
  const { questionType } = useSel((s) => s.questions.entities[id])
  const answers = useSel((s) =>
    _.values(s.answers.entities).filter((a) => a.active && a.questionId === id)
  )
  const questionAnswer = useData((rs) => (rs.callSession.questionAnswers || {})[id])
  const currentQuestionId = useData((rs) => rs.call.currentQuestionId)
  const current = currentQuestionId === id && inputStage === 'answers'
  const [answerText, setAnswerText] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [answerIds, setAnswerIds] = useState(new Set())
  const storedAnswerText = useSel((s) => s.desktopCaller.answerTexts[id])
  const storedAnswer = useSel((s) => s.desktopCaller.questionAnswers[id])

  const act = useAct()

  // If an undo results in this question becoming active again, pre-populate with previous answer
  useEffect(() => {
    if (current && storedAnswerText) {
      setAnswerText(storedAnswerText)
    }
  }, [current, storedAnswerText])

  // Likewise for multiple select questions
  useEffect(() => {
    if (current && Array.isArray(storedAnswer)) {
      setAnswerIds(new Set(storedAnswer))
    }
  }, [current, storedAnswer])

  const submitAnswer = (id, answer) => {
    console.log('submitAnswer', id, answer)
    if (!current) return Promise.resolve()
    const action = questionType === 'multiple_select' ?
      act.desktopCaller.answerMultipleSelectQuestion : act.desktopCaller.answerQuestion
    return action(id, answer).finally(() => setSubmitting(false))
  }

  const handleAnswerClick = (id, answerId) => {
    if (submitting) return

    setSubmitting(true)
    submitAnswer(id, answerId)
  }

  const handleMultipleSelectAnswerClick = (id, answerId, checked) => {
    setAnswerIds((prev) => {
      const newAnswerIds = new Set(prev)
      if (checked) {
        newAnswerIds.add(answerId)
      } else {
        newAnswerIds.delete(answerId)
      }
      return newAnswerIds
    })
  }

  const handleSaveAnswerIdsClick = () => {
    setSubmitting(true)
    return submitAnswer(id, answerIds)
  }

  const handleSaveTextClick = () => {
    return act.desktopCaller.answerQuestionWithText(id, answerText)
  }

  const handleInstructionQuestionClick = () => {
    return act.desktopCaller.answerInstructionQuestion(id)
  }

  function undo() {
    return act.desktopCaller.undoLast()
  }

  const typeLabel =
    questionType == 'instruction' ? (
      <span>{i18n.t('user.campaigns.phone.instruction')}</span>
    ) : (
      <span>
        {i18n.t('user.campaigns.phone.question')} {index}
      </span>
    )

  return (
    <LabeledCard
      className={current ? 'question current' : 'question'}
      label={<><SvgIconsCallerQuestion /> {typeLabel}</>}

    >

      <p className="no-margin-top">
        <RenderBreaks text={script.questions[id]?.question} />
      </p>

      {questionType === 'text' && (
        <>
          {id === currentQuestionId && (
            <div className="">
              <div>
                <textarea
                  rows={3}
                  value={answerText}
                  onChange={(e) => setAnswerText(e.target.value)}
                ></textarea>
              </div>
              <div>
                <ServerWaitButton
                  type="button"
                  className="button primary margin-top"
                  onClick={handleSaveTextClick}
                  disabled={(answerText || '').trim().length === 0}
                >
                  {i18n.t('shared.messages.save')}
                </ServerWaitButton>
              </div>
            </div>
          )}
          {id !== currentQuestionId && (
            <div className="bold">
              <p>{storedAnswerText}</p>
            </div>
          )}
        </>
      )}

      {questionType === 'instruction' && id === currentQuestionId && (
        <div>
          <ServerWaitButton
            type="button"
            className="button primary margin-top"
            onClick={handleInstructionQuestionClick}
          >
            {i18n.t('shared.messages.next')}
          </ServerWaitButton>
        </div>
      )}

      {questionType === 'send_sms' && <SmsQuestion id={id} />}
      {questionType === 'transfer' && <TransferQuestion id={id} />}

      {questionType === 'multiple_choice' && answers.length > 0 &&
        <div className="answers">
          {answers.map(({ id: answerId, answer }) => (
            <Radio
              onChange={() => { handleAnswerClick(id, answerId) }}
              disabled={!current || submitting}
              checked={answerId === questionAnswer}
              label={script.answers[answerId]?.answer}
              key={answerId}
            />
          ))}
        </div>
      }

      {questionType === 'multiple_select' && answers.length > 0 &&
        <div className="answers">
          {answers.map(({ id: answerId, answer }) => (
            <Checkbox
              onChange={(e) => { handleMultipleSelectAnswerClick(id, answerId, e.target.checked) }}
              disabled={!current || submitting}
              checked={answerIds.has(answerId)}
              label={script.answers[answerId]?.answer}
              key={answerId}
            />
          ))}
          {current && (
            <div>
              <ServerWaitButton
                type="button"
                className="button primary margin-top"
                onClick={handleSaveAnswerIdsClick}
                disabled={false}
              >
                {i18n.t('shared.messages.save')}
              </ServerWaitButton>
            </div>
          )}
        </div>
      }

      {['multiple_choice', 'multiple_select', 'text', 'instruction'].includes(questionType) &&
        id === previousQuestionId && (
          <div>
            <ServerWaitButton
              type="button"
              className="button primary margin-top"
              onClick={undo}
            >
              {i18n.t('shared.messages.undo')}
            </ServerWaitButton>
          </div>
        )}


    </LabeledCard>
  )
}
