import { useEffect } from "react"
import AsyncSelect from 'Shared/components/AsyncSelect'
import { Popover } from 'react-tiny-popover'
import FilterList from './FilterList'
import './Filters.scoped.scss'

export default function Filters({ storageKey, filters, sorters, url, onChange }) {
  const savedFilterState = JSON.parse(window.sessionStorage.getItem(storageKey))
  const [filterState, setFilterState] = useState(savedFilterState || [])
  const [order, setOrder] = useState('')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const setFilter = (name, value, label) => {
    const newFilterState = [...filterState]
    const index = newFilterState.findIndex(filter => filter.name === name)
    if (index !== -1) {
      newFilterState[index] = { name, value, label }
    } else {
      newFilterState.push({ name, value, label })
    }
    setFilterState(newFilterState)
  }

  const handleOrderChange = (e) => {
    setOrder(e.target.value)
  }

  const removeFilter = (name) => {
    setFilterState(filterState.filter(filter => filter.name !== name))
  }

  useEffect(() => {
    onChange(filterState, order)
    window.sessionStorage.setItem(storageKey, JSON.stringify(filterState))
  }, [filterState, order])


  return (
    <div className="filters-root">

      <div className="filter-and-sort">
        {!!filters.length && (
        <div className="add-filter">
          <Popover
            isOpen={isPopoverOpen}
            positions={['bottom', 'top']}
            padding={0}
            reposition={true}
            content={() => (
              <FilterList
                filterState={filterState}
                setFilter={setFilter}
                removeFilter={removeFilter}
                filters={filters}
                close={() => setIsPopoverOpen(false)}
              />
            )}
            onClickOutside={() => setIsPopoverOpen(false)}
          >
            <button className="button secondary small" type="button" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
              Add filter
              <SvgIconsPlus />
            </button>
          </Popover>
        </div>)}
        <div className="sort">
          {sorters.length > 0 && (
            <>
              <span>Sort by:</span>
              <select value={order} onChange={handleOrderChange}>
                {sorters.map(sorter => (
                  <option key={sorter.label} value={`${sorter.column}-${sorter.order}`}>
                    {sorter.label}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>

      {filterState.length > 0 &&
        <div className="rules">
          {
            filterState.map((filter, index) => {
              return (
                <div key={index} className="rule">
                  {filter.name}: {filter.label}
                  <div onClick={() => removeFilter(filter.name)}>
                    <SvgIconsCross />
                  </div>
                </div>
              )
            })
          }
        </div>
      }

    </div>
  )
}

