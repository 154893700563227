import { createSlice } from '@reduxjs/toolkit'
import { generateEntitySlices, generateSlices } from 'Shared/sliceHelpers'

const entitySliceNames = ['questions', 'answers', 'targets', 'campaignTargets', 'campaigns', 'users', 'calls', 'callSessions']

const sliceNames = [
  'user',
  'call',
  'me',
  'outcomes',
  'target',
  'outboundCall',
  'campaign',
]

const local = createSlice({
  name: 'local',
  initialState: {
    errors: [],
    showInputSetup: true,
    instructionsViewed: false,
    inputsConfigured: false,
    avDevices: [],
    completeGoalOnDone: false,
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
}).reducer

const callSession = createSlice({
  name: 'callSession',
  initialState: {
    callStage: 'waiting_for_connection',
    inputStage: 'outcome',
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
    set: (s, a) => a.payload,
  },
}).reducer

const desktopCaller = createSlice({
  name: 'desktopCaller',
  initialState: {
    answeredQuestionIds: [],
    questionAnswers: {},
    answerTexts: {},
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
    set: (s, a) => a.payload,
    addQuestionAnswerText: (s, a) => {
      s.answerTexts = { ...s.answerTexts, ...a.payload }
    },
    addQuestionAnswer: (s, a) => {
      s.questionAnswers = { ...s.questionAnswers, ...a.payload }
    }
  },
}).reducer

const reducers = {
  local,
  callSession,
  desktopCaller,
  ...generateSlices(sliceNames),
  ...generateEntitySlices(entitySliceNames),
}

export default reducers
