import './PillEditor.scoped.scss'
import { Popover } from 'react-tiny-popover'
import MergeTagList from './MergeTagList.jsx'
import {
  Editor,
  EditorState,
  Modifier,
  CompositeDecorator,
  RichUtils,
  convertFromRaw,
} from 'draft-js'
import generateRawText from './generateRawText'
import generateHtml from './generateHtml'
import convertTextToDraftJsFormat from './convertTextToDraftJsFormat'
import convertFromHtml from './convertFromHtml'
import { useEffect } from 'react'
import EditLink from './EditLink'

import emojiData from '@emoji-mart/data'
import EmojiPicker from '@emoji-mart/react'

const Link = ({ contentState, entityKey, children }) => {
  const { url, color } = contentState.getEntity(entityKey).getData()
  const styles = {
    color: color,
  }
  return (
    <a href={url} style={styles}>
      {children}
    </a>
  )
}

const makeEntityFinder = (type) => (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === type
  }, callback)
}

const PillEditor = ({
  webLink = false,
  condensed = false,
  initialValue,
  onChange,
  availableTags,
  enableEmojis = false,
  richText = false,
  singleLine = false,
  setIsDropdownOpen,
  ...props
}) => {
  let value = initialValue || ''
  const tagConversions = Object.values(availableTags)
    .map((tags) => {
      return tags.map((tag) => [tag.slug, tag.label])
    })
    .flat()
  tagConversions.forEach(([slug, label]) => {
    value = value.replaceAll('{{' + slug + '}}', '{{' + label + '}}')
  })

  return (
    <div {...props}>
      <EntityEditor
        initialValue={value}
        onChange={(body) => {
          tagConversions.forEach(([slug, label]) => {
            body = body.replaceAll('{{' + label + '}}', '{{' + slug + '}}')
          })
          return onChange(body)
        }}
        webLink={webLink}
        entities={availableTags}
        condensed={condensed}
        richText={richText}
        singleLine={singleLine}
        enableEmojis={enableEmojis}
        setIsInsideDropdownOpen={setIsDropdownOpen}
      />
    </div>
  )
}

const Placeholder = (props) => (
  <span {...props} className="merge-tag" contentEditable="false">
    {props.children}
  </span>
)

const STYLES = ['BOLD', 'ITALIC', 'UNDERLINE']

const ICONS = {
  BOLD: (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.16668 4.86683C6.4931 4.66806 6.76857 4.39578 6.97112 4.07169C7.17368 3.7476 7.29772 3.38068 7.33335 3.00016C7.33953 2.65609 7.27787 2.31416 7.15188 1.99392C7.0259 1.67367 6.83806 1.38139 6.59909 1.13376C6.36012 0.886121 6.07471 0.68799 5.75916 0.55068C5.44361 0.41337 5.1041 0.33957 4.76002 0.333496H0.43335V9.66683H5.10002C5.42745 9.66335 5.75099 9.5954 6.05217 9.46687C6.35334 9.33834 6.62625 9.15174 6.8553 8.91773C7.08436 8.68373 7.26507 8.40689 7.38713 8.10304C7.50919 7.79919 7.5702 7.47426 7.56668 7.14683V7.06683C7.56691 6.6049 7.4353 6.15251 7.1873 5.7628C6.9393 5.37309 6.58523 5.06226 6.16668 4.86683V4.86683ZM1.76668 1.66683H4.56668C4.8508 1.65804 5.1308 1.73635 5.36913 1.89126C5.60746 2.04618 5.79271 2.27028 5.90002 2.5335C6.0086 2.88535 5.97348 3.26589 5.80234 3.59193C5.6312 3.91798 5.33795 4.16301 4.98668 4.2735C4.85027 4.31347 4.70883 4.33368 4.56668 4.3335H1.76668V1.66683ZM4.83335 8.3335H1.76668V5.66683H4.83335C5.11746 5.65804 5.39747 5.73635 5.6358 5.89126C5.87413 6.04618 6.05937 6.27028 6.16668 6.5335C6.27526 6.88535 6.24015 7.26589 6.06901 7.59193C5.89786 7.91798 5.60462 8.16301 5.25335 8.2735C5.11694 8.31347 4.9755 8.33368 4.83335 8.3335V8.3335Z"
        fill="black"
      />
    </svg>
  ),
  ITALIC: (
    <svg
      width="4"
      height="10"
      viewBox="0 0 4 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83996 3.00016H3.17329L1.70662 9.66683H0.373291L1.83996 3.00016ZM2.95996 0.333496C2.8281 0.333496 2.69921 0.372595 2.58958 0.44585C2.47994 0.519104 2.3945 0.623223 2.34404 0.745041C2.29358 0.866858 2.28038 1.0009 2.3061 1.13022C2.33182 1.25954 2.39532 1.37833 2.48855 1.47157C2.58179 1.5648 2.70058 1.6283 2.8299 1.65402C2.95922 1.67974 3.09326 1.66654 3.21508 1.61608C3.3369 1.56562 3.44102 1.48018 3.51427 1.37054C3.58752 1.26091 3.62662 1.13202 3.62662 1.00016C3.62662 0.823352 3.55639 0.653783 3.43136 0.528758C3.30634 0.403734 3.13677 0.333496 2.95996 0.333496Z"
        fill="black"
      />
    </svg>
  ),
  UNDERLINE: (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66671 10.3335V11.6668H0.333374V10.3335H9.66671ZM7.66671 5.81016C7.64476 6.25015 7.51414 6.67785 7.28653 7.05504C7.05893 7.43222 6.74142 7.74714 6.36239 7.97166C5.98336 8.19617 5.5546 8.32328 5.11445 8.34164C4.67429 8.35999 4.23645 8.26901 3.84004 8.07683C3.38313 7.87916 2.99564 7.54958 2.72722 7.13032C2.45879 6.71105 2.32166 6.22119 2.33337 5.7235V0.33683H1.00004V5.81016C1.0226 6.43777 1.19261 7.05125 1.49632 7.60093C1.80003 8.15062 2.2289 8.62108 2.74822 8.97421C3.26754 9.32735 3.86271 9.55325 4.48556 9.63362C5.1084 9.71399 5.74142 9.64657 6.33337 9.43683C7.12078 9.17438 7.80394 8.6676 8.28353 7.99018C8.76311 7.31277 9.01412 6.50004 9.00004 5.67016V0.33683H7.66671V5.81016ZM7.66671 0.333496H9.00004H7.66671ZM2.33337 0.333496H1.00004H2.33337Z"
        fill="black"
      />
    </svg>
  ),
  EMOJI: (
    <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect width="24" height="24" />
        <rect x="2" y="2" width="20" height="20" rx="10" fill="##2849d6" />
      </g>
    </svg>
  ),
  LINK: (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33337 7.33317H3.66671C2.78265 7.33317 1.93481 6.98198 1.30968 6.35686C0.684563 5.73174 0.333374 4.88389 0.333374 3.99984C0.333374 3.11578 0.684563 2.26794 1.30968 1.64281C1.93481 1.01769 2.78265 0.666504 3.66671 0.666504H6.33337V1.99984H3.66671C3.13627 1.99984 2.62757 2.21055 2.25249 2.58562C1.87742 2.9607 1.66671 3.4694 1.66671 3.99984C1.66671 4.53027 1.87742 5.03898 2.25249 5.41405C2.62757 5.78912 3.13627 5.99984 3.66671 5.99984H6.33337V7.33317ZM10.3334 0.666504H7.66671V1.99984H10.3334C10.8638 1.99984 11.3725 2.21055 11.7476 2.58562C12.1227 2.9607 12.3334 3.4694 12.3334 3.99984C12.3334 4.53027 12.1227 5.03898 11.7476 5.41405C11.3725 5.78912 10.8638 5.99984 10.3334 5.99984H7.66671V7.33317H10.3334C11.2174 7.33317 12.0653 6.98198 12.6904 6.35686C13.3155 5.73174 13.6667 4.88389 13.6667 3.99984C13.6667 3.11578 13.3155 2.26794 12.6904 1.64281C12.0653 1.01769 11.2174 0.666504 10.3334 0.666504ZM9.66671 3.33317H4.33337V4.6665H9.66671V3.33317Z"
        fill="black"
      />
    </svg>
  ),
}

const EntityEditor = ({
  webLink,
  condensed,
  initialValue,
  onChange,
  entities,
  richText = false,
  singleLine = false,
  enableEmojis = false,
  setIsInsideDropdownOpen,
}) => {
  const [editorState, setEditorState] = React.useState(null)
  const [isFirstPopoverOpen, setIsFirstPopoverOpen] = useState(false)
  const [isSecondPopoverOpen, setIsSecondPopoverOpen] = useState(false)
  const [isEmojiPopoverOpen, setIsEmojiPopoverOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const editorRef = React.useRef(null)

  // set the initial editor state
  // this transmutes the stored content from either raw/html into pill form
  useEffect(() => {
    const text = initialValue || ''
    let raw = null
    if (richText) {
      raw = convertFromHtml(text)
    } else {
      raw = convertFromRaw(convertTextToDraftJsFormat(text))
    }

    const decorator = new CompositeDecorator([
      {
        strategy: makeEntityFinder('PLACEHOLDER'),
        component: Placeholder,
      },
      {
        strategy: makeEntityFinder('LINK'),
        component: Link,
      },
    ])

    setEditorState(EditorState.createWithContent(raw, decorator))
  }, [])

  const handleChange = (editorState) => {
    setEditorState(editorState)
    if (onChange) {
      const contentState = editorState.getCurrentContent()
      const output = richText ? generateHtml(contentState) : generateRawText(contentState)
      onChange(output)
    }
  }

  const handleLinkEdit = (editorState) => {
    setEditorState(editorState)
    handleChange(editorState)
  }

  function isActive(style) {
    const currentStyle = editorState.getCurrentInlineStyle()
    return currentStyle.has(style)
  }

  function isLinkActive() {
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const block = contentState.getBlockForKey(startKey)
    const linkKey = block.getEntityAt(startOffset)

    if (linkKey === null) {
      return false
    }

    const entity = contentState.getEntity(linkKey)
    return entity.getType() === 'LINK'
  }

  function toggle(style) {
    const updatedState = RichUtils.toggleInlineStyle(editorState, style)
    setEditorState(updatedState)
    handleChange(updatedState)
  }

  function handleKeyCommand(command) {
    if (!richText) {
      return null
    }
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      setEditorState(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const insertPlaceholder = (label) => {
    const currentContent = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    currentContent.createEntity('PLACEHOLDER', 'IMMUTABLE', { label })
    const entityKey = currentContent.getLastCreatedEntityKey()

    const textWithEntity = Modifier.insertText(
      currentContent,
      selection,
      label,
      null,
      entityKey
    )

    setEditorState(EditorState.push(editorState, textWithEntity, 'insert-characters'))
    // find a better way
    setTimeout(focus, 100)
  }

  const insertEmoji = (emoji) => {
    const contentState = editorState.getCurrentContent()
    const selectionState = editorState.getSelection()
    const collapsedSelection = selectionState.isCollapsed()
      ? selectionState
      : selectionState.merge({
          anchorOffset: selectionState.getEndOffset(),
          focusOffset: selectionState.getEndOffset(),
        })
    const newContentState = Modifier.insertText(
      contentState,
      collapsedSelection,
      emoji.native
    )
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters'
    )
    setEditorState(newEditorState)
    focus()
  }

  const focus = () => {
    editorRef.current.focus()
  }

  function blockStyleFn(contentBlock) {
    if (contentBlock.getType() === 'unstyled') {
      return 'injected-styles'
    }
  }

  const keyboardShortcuts = (event) => {
    if (event.key === 'b' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      toggle('BOLD')
    }
    if (event.key === 'i' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      toggle('ITALIC')
    }
    if (event.key === 'u' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      toggle('UNDERLINE')
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyboardShortcuts)
    return () => {
      document.removeEventListener('keydown', keyboardShortcuts)
    }
  }, [editorState])

  return editorState ? (
    <div className={singleLine ? 'pill-editor single-line' : 'pill-editor'}>
      {richText && (
        <div className="toolbar">
          {STYLES.map((style, i) => {
            return (
              <button
                key={`style-${i}`}
                className={`button smallest ${isActive(style) ? 'active' : ''}`}
                onClick={(e) => {
                  e.preventDefault()
                  toggle(style)
                }}
              >
                {ICONS[style]}
              </button>
            )
          })}
          <Popover
            containerStyle={{ zIndex: 1010 }}
            containerClassName="popover-container"
            isOpen={isFirstPopoverOpen}
            positions={['bottom', 'top']}
            padding={0}
            reposition={true}
            align={'start'}
            content={() => (
              <EditLink
                mergeTags={entities}
                visible={true}
                close={() => setIsFirstPopoverOpen(false)}
                editorState={editorState}
                onSave={handleLinkEdit}
                setIsDropdownOpen={setIsDropdownOpen}
              />
            )}
            onClickOutside={() => setIsFirstPopoverOpen(isDropdownOpen)}
          >
            <button
              onClick={(e) => {
                e.preventDefault()
                setIsFirstPopoverOpen(!isFirstPopoverOpen)
              }}
              className={`button smallest ${isLinkActive() ? 'active' : ''}`}
            >
              {ICONS.LINK}
            </button>
          </Popover>
          <Popover
            containerStyle={{ zIndex: 1010 }}
            containerClassName="popover-container"
            isOpen={isSecondPopoverOpen}
            positions={['bottom', 'top']}
            padding={0}
            reposition={true}
            align={'start'}
            content={() => (
              <MergeTagList
                mergeTags={entities}
                insert={insertPlaceholder}
                close={() => setIsSecondPopoverOpen(false)}
              />
            )}
            onClickOutside={() => setIsSecondPopoverOpen(false)}
          >
            <button
              onClick={(e) => {
                e.preventDefault()
                setIsSecondPopoverOpen(!isSecondPopoverOpen)
              }}
              className="button smallest"
            >
              Merge tags
            </button>
          </Popover>
        </div>
      )}

      <div
        className={`text-box ${richText ? 'border margin-left margin-right' : !webLink ? 'flex-center' : ''}`}
      >
        <div className={`grow ${webLink ? 'break-word' : ''}`}>
          <Editor
            editorState={editorState}
            ref={editorRef}
            onChange={handleChange}
            handleKeyCommand={handleKeyCommand}
            blockStyleFn={blockStyleFn}
          />
        </div>
        <div
          className="merge-tags-dropdown align-center gap-5"
          style={
            webLink ? { transform: 'translate(-5px, 5px)', marginTop: '5px' } : undefined
          }
        >
          {!richText && (
            <>
              {enableEmojis && (
                <Popover
                  containerStyle={{ zIndex: 1010 }}
                  containerClassName="popover-container"
                  isOpen={isEmojiPopoverOpen}
                  positions={['bottom', 'top']}
                  padding={0}
                  reposition={true}
                  align={'start'}
                  content={() => (
                    <EmojiPicker data={emojiData} onEmojiSelect={insertEmoji} />
                  )}
                  onClickOutside={() => setIsEmojiPopoverOpen(false)}
                >
                  <button
                    className="add-emoji-button"
                    type="button"
                    onClick={() => setIsEmojiPopoverOpen(!isEmojiPopoverOpen)}
                  >
                    <SvgIconsAddEmoji />
                  </button>
                </Popover>
              )}

              <Popover
                containerStyle={{ zIndex: 1010 }}
                containerClassName="popover-container"
                isOpen={isDropdownOpen}
                positions={['bottom', 'top']}
                padding={0}
                reposition={true}
                align={'start'}
                content={() => (
                  <MergeTagList
                    mergeTags={entities}
                    insert={insertPlaceholder}
                    close={() => {
                      setIsDropdownOpen(false)
                      setIsInsideDropdownOpen?.(false) || (() => {})
                    }}
                  />
                )}
                onClickOutside={() => {
                  setIsDropdownOpen(false)
                  setIsInsideDropdownOpen?.(false) || (() => {})
                }}
              >
                <button
                  className="merge-tags-dropdown-button"
                  type="button"
                  onClick={() => {
                    setIsDropdownOpen(!isDropdownOpen)
                    setIsInsideDropdownOpen?.(true) || (() => {})
                  }}
                >
                  <SvgIconsPlus />
                </button>
              </Popover>
            </>
          )}
        </div>
      </div>

      {!condensed && (
        <div className="merge-tags">
          <p>Add customisation:</p>
          <div className="inner-list">
            {Object.values(entities)
              .flat()
              .filter((tag) => tag.featured)
              .map((tag) => (
                <button
                  key={tag.slug}
                  type="button"
                  onClick={() => insertPlaceholder(tag.label)}
                  className="merge-tag"
                >
                  {tag.label}
                </button>
              ))}
          </div>
        </div>
      )}
    </div>
  ) : null
}

const MemoizedPillEditor = React.memo(PillEditor)
export default MemoizedPillEditor
