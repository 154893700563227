import parsePhoneNumber from 'libphonenumber-js/max'

export default function validate(questions, answers, setValidationErrors, campaignType = null) {
  const validationErrors = []

  questions.forEach(q => {
    if (!q.active) return
    if (!q.question || q.question === '') {
      validationErrors.push('One of your questions has an empty title')
    }
    if (['multiple_choice', 'multiple_select'].includes(q.questionType)) {
      const multipleChoiceAnswers = answers.filter(a => a.active && String(a.questionId) === String(q.id) && a.answer)

      if (multipleChoiceAnswers.length <= 1) {
        validationErrors.push("One of your questions has one or zero answers")
      } else if (_.uniq(multipleChoiceAnswers.map(a => a.answer)).length < multipleChoiceAnswers.length) {
        validationErrors.push("Two of the answers on a question are the same")
      }
    }
    if (campaignType === 'sms_survey' && q.digitOrder === 1 && q.questionType === 'instruction') {
      validationErrors.push("You cannot have an instruction as the first question in an SMS survey")
    }

    if (q.questionType === 'send_sms') {
      if ((q.settings.textMessageBody || '').length < 3) {
        validationErrors.push("An SMS question is missing text message content")
      }
    }
    if (q.questionType === 'transfer') {
      const value = String(q.settings.toPhoneNumber).replace('+440', '+44')
      const country = String(value)[0] === '+' ? '' : window.ENV.COUNTRY.toUpperCase()
      const parsed = parsePhoneNumber(value, country)
      if ((parsed && !parsed.isValid()) || (parsed && parsed.number.length < 13)) {
        validationErrors.push("A call transfer is missing a valid phone number")
      }
    }
  })

  setValidationErrors(_.uniq(validationErrors))
}
