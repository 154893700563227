import PillEditor from "Shared/components/PillEditor/PillEditor"
import BlockStyles from "../../BlockStyles"

const ParagraphBlock = ({ block, active }) => {
  const act = useAct()
  const { mergeTags } = useSel(s => s.campaignBuilder)
  const updateText = (text) => act.email.blocks.updateParameters(block.id, {text})

  return (
    <div id={`block_${block.id}`}>

      { !active && <div dangerouslySetInnerHTML={{__html: block.parameters.text}} />}
      { active && <PillEditor initialValue={block.parameters.text} onChange={updateText} richText availableTags={mergeTags} condensed />}

      <BlockStyles block={block} active={active} />
    </div>
  )
}

export default ParagraphBlock
