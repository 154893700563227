import * as React from "react"

const SvgList = (props) => (

  <svg version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"  
  {...props}>
  <defs>
  <filter id="d" x="0%" y="0%" width="100%" height="100%">
  <feColorMatrix in="SourceGraphic" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
  </filter>
  <mask id="b">
  <g filter="url(#d)">
  <rect width="64" height="64" fillOpacity=".30196" fill="currentColor" />
  </g>
  </mask>
  <clipPath id="c">
  <rect width="64" height="64" fill="currentColor" />
  </clipPath>
  <g id="a" clipPath="url(#c)">
  <path d="m14.668 21.332c-2.2109 0-4-1.7891-4-4 0-2.207 1.7891-4 4-4 2.207 0 4 1.793 4 4 0 2.2109-1.793 4-4 4zm0 13.336c-2.2109 0-4-1.793-4-4 0-2.2109 1.7891-4 4-4 2.207 0 4 1.7891 4 4 0 2.207-1.793 4-4 4zm0 13.332c-2.2109 0-4-1.7891-4-4s1.7891-4 4-4c2.207 0 4 1.7891 4 4s-1.793 4-4 4z" fillRule="evenodd" fill="currentColor" />
  </g>
  </defs>
  <path d="m28 13.332h24c2.2109 0 4 1.793 4 4 0 2.2109-1.7891 4-4 4h-24c-2.2109 0-4-1.7891-4-4 0-2.207 1.7891-4 4-4zm0 13.336h24c2.2109 0 4 1.7891 4 4 0 2.207-1.7891 4-4 4h-24c-2.2109 0-4-1.793-4-4 0-2.2109 1.7891-4 4-4zm0 13.332h24c2.2109 0 4 1.7891 4 4s-1.7891 4-4 4h-24c-2.2109 0-4-1.7891-4-4s1.7891-4 4-4z" fillRule="evenodd" fill="currentColor" />

  </svg>
  
)

export default SvgList
