import { COLUMNS, HEADERS, URLS, FILTERS, SORTERS, ORDERS } from './constants'
import Filters from './Filters'
import dtNet from 'datatables.net'
import dtNetDt from 'datatables.net-dt'
import dtNetResponsive from 'datatables.net-responsive'
import { set } from 'lodash'
import { forwardRef, HTMLProps, RefObject } from 'react'
dtNet(window, $)
dtNetDt(window, $)
dtNetResponsive(window, $)


const PAGE_SIZE = 10
function filterValue(filterState, name) {
  const filter = filterState.find((f) => f.name === name)
  return filter ? filter.value : ''
}

const Table = forwardRef(
  (
    {
      table,
      scope = {},
      language = {},
      onClick,
      onlyColumns,
      paging = true,
      initialFilterState = [],
    }: {
      table: keyof typeof HEADERS
      scope?: Record<string, unknown>
      language?: Parameters<typeof $.fn.dataTable>[0]['language']
      onClick: HTMLProps<HTMLTableElement>['onClick']
    },
    componentRef: RefObject<HTMLTableElement>
  ) => {
    const ref = componentRef || useRef(null)

    const [filterState, setFilterState] = useState(initialFilterState)

    const [api, setApi] = useState(null)
    const columnHeaders = onlyColumns ? _.values(onlyColumns) : HEADERS[table]
    const columns = onlyColumns ? COLUMNS[table].filter(c => _.includes(_.keys(onlyColumns), c.data)) : COLUMNS[table]

    const filters = FILTERS[table]
    const sorters = SORTERS[table]
    const query = _.map(scope, (v, k) => {
      return `${_.snakeCase(k)}=${v}`
    }).join('&')
    const fullUrl = URLS[table] + '?' + query

    const initialFilters = COLUMNS[table].map((col, index) => {
      const filter = _.find(filters, { column: index })
      if (filter) {
        return { search: filter.options[0].value }
      } else {
        return null
      }
    })

    useEffect(() => {
      const dataTable = $(ref.current).dataTable({
        fnDrawCallback: function(oSettings) {
          const totalPages =
            oSettings._iDisplayLength === -1
              ? 0
              : Math.ceil(oSettings.fnRecordsDisplay() / oSettings._iDisplayLength)
          const finder = [
            '#',
            oSettings.sInstance,
            '_wrapper',
            ' ',
            '.dataTables_paginate',
          ].join('')

          if (totalPages <= 1) {
            $(finder).hide()
          } else {
            $(finder).show()
          }

          // If the table is targets, hide the last button
          if (table === 'targets') {
            const lastButton = document.querySelectorAll('.paginate_button.last')
            lastButton.forEach(button => button.style.display = 'none')
          }
        },
        processing: true,
        language: {
          ...language,
          processing: '<div style="padding-bottom: 15px;">Loading...</div>',
        },
        serverSide: true,
        lengthChange: false,
        info: false,
        paging: paging,
        responsive: true,
        searchCols: initialFilters,
        deferLoading: 0,
        ajax: {
          url: fullUrl,
          dataSrc: function(json) {
            // Special meaning of -1 means to just always display a next page (for slow counts)
            if (json.recordsTotal === -1) {
              if (json.data.length == PAGE_SIZE) {
                json.recordsTotal = json.start + PAGE_SIZE + 1
                json.recordsFiltered = json.start + PAGE_SIZE + 1
              } else {
                json.recordsTotal = json.start + json.data.length
                json.recordsFiltered = json.start + json.data.length
              }
            }

            const decodeHtmlEntities = (data) => {
              if (typeof data === 'string') {
                const txt = document.createElement("textarea")
                txt.innerHTML = data
                return txt.value
              } else if (Array.isArray(data)) {
                return data.map(item => decodeHtmlEntities(item))
              } else if (typeof data === 'object' && data !== null) {
                return Object.keys(data).reduce((acc, key) => {
                  acc[key] = decodeHtmlEntities(data[key])
                  return acc
                }, {})
              } else {
                return data
              }
            }
            json.data = json.data.map(row => decodeHtmlEntities(row))

            return json.data
          }
        },
        pageLength: PAGE_SIZE,
        pagingType: 'full_numbers',
        columns: columns,
        oLanguage: {
          sSearch: '',
        },
        order: ORDERS[table] || [],
        sDom: '<"top"i>frpt<"bottom"lp><"clear">',
      })

      $(dataTable.api().table().container()).find('input[type="search"]').off();

      // Debounce function for search input
      const debouncedSearch = _.debounce(function(value) {
        dataTable.api().search(value).draw();
      }, 500); // 500ms delay

      // Apply the debounced search to the DataTable search input
      $('input[type="search"]').on('keyup', function() {
        debouncedSearch(this.value);
      });

      setApi(dataTable.api())
      window.$.fn.dataTable.ext.errMode = 'throw'
    }, [])

    const applyFilters = (filterState, order) => {
      setFilterState(filterState)
      // Apply each filter form the table definition. Skip those with no column
      filters.filter((filter) => !!filter.column).forEach((filter, index) => {
        // match the filter to an entry in the filter state if possible
        const value = filterValue(filterState, filter.name)
        api.column(filter.column).search(value)
      })

      // Async filters and those with a customParam rather than a column have separate query params
      // This allows for easy filtering outside of the automatic datatable-rails magic
      const paramString = _.compact(filters.filter(filter => filter.customParam || filter.kind.includes('async')).map(filter => {
        const value = filterValue(filterState, filter.name)
        if (!value) return

        const key = filter.customParam ? filter.customParam : `${_.snakeCase(filter.entity)}_id`
        return `${key}=${encodeURIComponent(value)}`
      })).join('&')

      if (order) {
        const parts = order.split('-')
        api.order([parts[0], parts[1]])
      }
      const urlWithFilters = `${fullUrl}${paramString}`
      api.ajax.url(urlWithFilters).load()
    }

    const organisationKinds = JSON.parse(window.ENV.ORGANISATION_KINDS)
    let organisationName = ''
    if (organisationKinds) {
      organisationName = window.ENV.ORGANISATION_NAME
    }

    return (
      <div>
        {organisationName && table === "campaigns"
          && filterState.every(filter => filter.name !== 'Organisation type') &&
          <h4 className="no-margin-top">You are viewing campaigns set up by users within your organisation ({organisationName})</h4>}
        {organisationName && table === "searches"
          && filterState.every(filter => filter.name !== 'Organisation type')
          && filterState.every(filter => filter.name !== 'Organisation') &&
          <h4 className="no-margin-top">You are viewing audiences set up by users within your organisation ({organisationName})</h4>}
        {api && (
          <Filters
            filters={filters}
            sorters={sorters}
            onChange={applyFilters}
            storageKey={`table-${table}-filters`}
            url={fullUrl}
          />
        )}
        <div className="datatable">
          <table className="responsive" ref={ref} onClick={onClick}>
            <thead>
              <tr>
                {columnHeaders.map((header, i) => (
                  <th key={i}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    )
  }
)

export default Table
